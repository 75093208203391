import { usePrivyWagmi } from '@privy-io/wagmi-connector'
import { faCoreAddress } from 'config'
import { faCoreAbi } from 'constants/abi'
import { getAddress } from 'viem'
import { useContractRead } from 'wagmi'

interface UseSharesBalanceProps {
  subjectAddress: string
}

export const useSharesBalance = ({ subjectAddress }: UseSharesBalanceProps) => {
  const { wallet } = usePrivyWagmi()
  const userAddress = wallet?.address

  return useContractRead({
    abi: faCoreAbi,
    address: faCoreAddress,
    args: userAddress
      ? [getAddress(subjectAddress), getAddress(userAddress)]
      : undefined,
    enabled: !!userAddress,
    functionName: 'passBalances'
  })
}

export default useSharesBalance
