import { Box, Button, Heading, Input, VStack } from '@chakra-ui/react'
import { BackButton, NavigationBarContainer } from 'components/NavigationBar'
import useCreateCollection from 'hooks/useCreateCollection'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const CreateCollection = () => {
  const navigate = useNavigate()

  const [name, setName] = useState('')
  const [symbol, setSymbol] = useState('')

  const { createCollection } = useCreateCollection({
    collectionName: name,
    collectionSymbol: symbol
  })

  const onCreateClick = async () => {
    const receipt = await createCollection()
    console.log(receipt)
    switch (receipt.status) {
      case 'success':
        // TODO
        navigate(-1)
        break
      case 'reverted':
        console.log('TODO')
        break
    }
  }

  return (
    <Box bg="gray.700" minH="100svh">
      <NavigationBarContainer>
        <BackButton />
        <Heading size="md" alignSelf="center">
          Create Collection
        </Heading>
      </NavigationBarContainer>

      <VStack p={4} pt="72px" spacing={10}>
        <Input
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.currentTarget.value)}
        />

        <Input
          placeholder="Symbol"
          value={symbol}
          onChange={(e) => setSymbol(e.currentTarget.value)}
        />

        <Button
          w="full"
          size="lg"
          isDisabled={!name || !symbol}
          onClick={onCreateClick}
        >
          Create
        </Button>
      </VStack>
    </Box>
  )
}

export default CreateCollection
