import {
  Box,
  Heading,
  Tab,
  TabList,
  TabPanels,
  Tabs,
  VStack
} from '@chakra-ui/react'
import { BackButton, NavigationBarContainer } from 'components/NavigationBar'
import React from 'react'

import BuySharesPanel from './BuySharesPanel'
import SellSharesPanel from './SellSharesPanel'

const BuySellPass = () => {
  // TODO: get from API with ID?
  const creatorAddress = '0x7E3893E2Ee0fE4Bc014Fe326D5F7a3d4297e3C38'

  return (
    <Box>
      <NavigationBarContainer>
        <BackButton />
        <Heading size="md" alignSelf="center">
          XXX Creator Pass
        </Heading>
      </NavigationBarContainer>

      <VStack p={4} pt="72px">
        <Tabs variant="solid-rounded" w="full" isFitted>
          <TabList w="full">
            <Tab>Buy</Tab>
            <Tab>Sell</Tab>
          </TabList>

          <TabPanels>
            <BuySharesPanel creatorAddress={creatorAddress} />
            <SellSharesPanel creatorAddress={creatorAddress} />
          </TabPanels>
        </Tabs>
      </VStack>
    </Box>
  )
}

export default BuySellPass
