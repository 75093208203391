import {
  Button,
  Heading,
  Skeleton,
  Spacer,
  Text,
  VStack
} from '@chakra-ui/react'
import { usePrivyWagmi } from '@privy-io/wagmi-connector'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { formatAmountString } from 'utils/format'
import { getAddress } from 'viem'
import { useBalance } from 'wagmi'

const FundWallet = () => {
  const navigate = useNavigate()

  // TODO: get user ID
  const userId = '001122'

  // get balance
  const { wallet: activeWallet } = usePrivyWagmi()
  const { data: balance } = useBalance({
    address: activeWallet ? getAddress(activeWallet.address) : undefined
  })

  const onDoneClick = () => {
    // TODO: validate invite code with backend + finalize account creation
    navigate('/')
  }

  return (
    <VStack h="calc(100vh - 56px)" spacing={8} align="flex-start">
      <VStack spacing={8} align="flex-start" p={4}>
        <Heading>Deposit ETH to your friend.art account</Heading>

        <Text>{"You'll need ETH to buy creator passes and collect drops"}</Text>

        <VStack align="flex-start" spacing={0}>
          <Text fontSize="sm" textColor="gray.400">
            Balance:
          </Text>
          <Skeleton isLoaded={!!balance}>
            <Text fontWeight="bold" fontSize="xl">
              {formatAmountString(balance?.formatted || '')} ETH
            </Text>
          </Skeleton>
        </VStack>
      </VStack>

      <Spacer />

      <VStack w="full" spacing={8} bg="gray.700" p={4} borderTopRadius="2xl">
        <VStack w="full" align="flex-start" spacing={8}>
          <Heading size="md">Deposit from crypto wallet</Heading>
          <Text>
            Open the browser you use with your other wallet and go to:
          </Text>
          <Text
            fontWeight="bold"
            textColor="accent"
          >{`friend.art/deposit/${userId}`}</Text>
        </VStack>
        <Button
          variant="ghost"
          size="sm"
          textColor="gray.400"
          onClick={onDoneClick}
        >
          Skip for now
        </Button>
      </VStack>
    </VStack>
  )
}

export default FundWallet
