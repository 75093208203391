import {
  Box,
  Image,
  SimpleGrid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs
} from '@chakra-ui/react'
import CreatorPassGrid from 'components/CreatorPassesGrid'
import { NavigationBar } from 'components/NavigationBar'
import React from 'react'
import { passes } from 'stub'

interface Item {
  imageUrl: string
}

// TODO: remove stubs
const userItems: Item[] = Array(9).fill({
  imageUrl:
    'https://i.seadn.io/gcs/files/640c9b283dd80e221a26ff5018530708.png?auto=format&dpr=1&w=1000'
})

const UserPortfolio = () => {
  return (
    <Box>
      <NavigationBar title="Portfolio" showBackButton />

      <Tabs w="full" variant="solid-rounded" isFitted p={4} pb="50px">
        <TabList w="full">
          <Tab>Items</Tab>
          <Tab>Passes</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <SimpleGrid columns={2} spacing={4}>
              {userItems.map((item, i) => {
                return <Image key={i} src={item.imageUrl} borderRadius="lg" />
              })}
            </SimpleGrid>
          </TabPanel>
          <TabPanel>
            <CreatorPassGrid passes={passes} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  )
}

export default UserPortfolio
