import { tabsAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys)

const baseStyle = definePartsStyle({
  tab: {
    _selected: {
      bg: 'gray.500',
      color: 'white'
    },
    bg: 'blue.100',
    color: 'white',
    fontWeight: 'bold'
  },
  tablist: {
    bg: 'gray.600',
    borderRadius: 'full',
    width: 'fit-content'
  },
  tabpanel: {
    p: 0,
    py: 4
  }
})

export const tabsTheme = defineMultiStyleConfig({ baseStyle })
