import {
  Button,
  Heading,
  HStack,
  Image,
  Link,
  SimpleGrid,
  Spacer,
  Text,
  VStack
} from '@chakra-ui/react'
import useClaimDrop from 'hooks/useClaimDrop'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

export interface CreatorDrop {
  address: string
  caption: string
  createdAt: number
  creator: {
    id: string
    name: string
    pfpUrl: string
  }
  id: number
  item: {
    imageUrl: string
    name: string
  }
  totalMinted: number
  totalSupply: number
}

const CreatorDropCard = ({ drop }: { drop: CreatorDrop }) => {
  // TODO: we need to check if the user can claim this drop
  // do they have a pass?
  // has the drop started?
  const { writeAsync } = useClaimDrop({
    dropAddress: drop.address,
    dropId: drop.id
  })

  return (
    <VStack
      borderBottom="1px solid"
      borderBottomColor="gray.600"
      pb={6}
      spacing={6}
    >
      <HStack align="flex-start" spacing={3}>
        <Link as={RouterLink} to={`/creator/${drop.creator.id}`} flexShrink={0}>
          <Image src={drop.creator.pfpUrl} boxSize="40px" borderRadius="full" />
        </Link>
        <VStack w="full" align="flex-start">
          <HStack w="full">
            <Link
              as={RouterLink}
              to={`/creator/${drop.creator.id}`}
              flexShrink={0}
            >
              <Heading size="sm">{drop.creator.name}</Heading>
            </Link>
            <Spacer />
            <Text textColor="gray.400" fontSize="xs">
              {new Date(drop.createdAt).toLocaleString()}
            </Text>
          </HStack>
          <Text fontSize="sm">{drop.caption}</Text>
        </VStack>
      </HStack>

      <VStack align="flex-start">
        <Image src={drop.item.imageUrl} borderRadius="lg" />
        <Text fontSize="xs" textColor="gray.400">
          {drop.item.name}
        </Text>
      </VStack>

      <Button w="full" isDisabled={!writeAsync} onClick={() => writeAsync?.()}>
        Collect
      </Button>
    </VStack>
  )
}

interface CreatorDropsListProps {
  drops: CreatorDrop[]
}

const CreatorDropsList = ({ drops }: CreatorDropsListProps) => {
  return (
    <SimpleGrid spacing={6}>
      {drops.map((drop, i) => {
        return <CreatorDropCard key={i} drop={drop} />
      })}
    </SimpleGrid>
  )
}

export default CreatorDropsList
