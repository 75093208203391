import { getDefaultWallets } from '@rainbow-me/rainbowkit'
import { getAddress } from 'viem'
import { Chain, configureChains, createConfig } from 'wagmi'
import { avalancheFuji, base } from 'wagmi/chains'
import { publicProvider } from 'wagmi/providers/public'

const envChainId = process.env.REACT_APP_CHAIN_ID
if (!envChainId) {
  throw new Error('REACT_APP_CHAIN_ID is not set')
}

let chain: Chain
switch (envChainId) {
  case '43113':
    chain = avalancheFuji
    break
  case '8453':
    chain = base
    break
  default:
    throw new Error(`Unsupported chain ID: ${envChainId}`)
}

export const appChain = chain

// TODO: custom provider
export const wagmiChainsConfig = configureChains([appChain], [publicProvider()])

// TODO: app name + project ID
const { connectors } = getDefaultWallets({
  appName: 'App Name',
  chains: wagmiChainsConfig.chains,
  projectId: 'YOUR_PROJECT_ID'
})

export const webWagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient: wagmiChainsConfig.publicClient
})

const faCoreAddressByChainId = {
  43113: '0xA93CB9B682bD4b36B45729D246ff6a527AF6CADD',
  8453: '0x'
}

const faFactoryDropAddressByChainId = {
  43113: '0x801CC9B30944f08A91a5FA8fc521584F97a9f027',
  8453: '0x'
}

export const faCoreAddress = getAddress(faCoreAddressByChainId[envChainId])
export const faFactoryDropAddress = getAddress(
  faFactoryDropAddressByChainId[envChainId]
)
