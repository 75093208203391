import {
  Box,
  Button,
  Center,
  Image,
  Input,
  Text,
  Textarea,
  VStack
} from '@chakra-ui/react'
import { usePrivy } from '@privy-io/react-auth'
import CameraIcon from 'assets/camera.svg'
import { useGetCurrentUser, usePatchCurrentUser } from 'hooks/useBarn'
import React, { useEffect, useRef, useState } from 'react'
import { zeroAddress } from 'viem'

interface EditProfileFormProps {
  onEditProfileSuccess: () => void
  defaultUsername?: string
}

const EditProfileForm = ({
  defaultUsername,
  onEditProfileSuccess
}: EditProfileFormProps) => {
  const { user } = usePrivy()
  const { data: barnUser } = useGetCurrentUser()
  const { isLoading: isPatchingCurrentUser, mutateAsync: patchCurrentUser } =
    usePatchCurrentUser()

  const [username, setUsername] = useState<string>(
    barnUser?.username || defaultUsername || ''
  )
  const isUsernameValid = username.length > 0

  const [bio, setBio] = useState<string>(barnUser?.description || '')

  const inputRef = useRef<HTMLInputElement>(null)
  const [pfpUrl, setPfpUrl] = useState<string | undefined>(barnUser?.pfp_url)
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileObj = event.target.files && event.target.files[0]
    if (!fileObj) {
      return
    }

    // TODO: do something with the file

    console.log('fileObj is', fileObj)

    // 👇️ reset file input
    event.target.value = ''

    // 👇️ is now empty
    console.log(event.target.files)

    // 👇️ can still access file object here
    console.log(fileObj)
    console.log(fileObj.name)
  }

  useEffect(() => {
    if (!barnUser) return
    setUsername(barnUser.username || defaultUsername || '')
    setBio(barnUser.description || '')
    setPfpUrl(barnUser.pfp_url)
  }, [barnUser, defaultUsername])

  const onSetupProfileClick = async () => {
    if (!user || !user.wallet || !barnUser) return

    try {
      await patchCurrentUser({
        address:
          barnUser.address === zeroAddress ? user.wallet.address : undefined,
        description: bio,
        pfpUrl,
        username
      })

      onEditProfileSuccess()
    } catch {
      // TODO: handle error
    }
  }

  return (
    <Box bg="gray.700" w="full" p={4} borderTopRadius="2xl">
      <VStack spacing={8}>
        {pfpUrl ? (
          <Image src={pfpUrl} boxSize="70px" borderRadius="full" />
        ) : (
          <Center
            boxSize="70px"
            border="2px solid"
            borderRadius="full"
            borderColor="gray.500"
          >
            <input
              style={{ display: 'none' }}
              ref={inputRef}
              type="file"
              accept="image/png, image/gif, image/jpeg"
              onChange={handleFileChange}
            />
            <Image
              src={CameraIcon}
              boxSize="30px"
              onClick={() => inputRef?.current?.click()}
            />
          </Center>
        )}

        <VStack w="full" align="flex-start">
          <Text fontSize="sm" textColor="gray.400">
            Enter username:
          </Text>
          <Input
            value={username}
            onChange={(e) => {
              setUsername(e.currentTarget.value)
            }}
          />
        </VStack>

        <VStack w="full" align="flex-start">
          <Text fontSize="sm" textColor="gray.400">
            Enter bio (optional):
          </Text>
          <Textarea
            value={bio}
            onChange={(e) => setBio(e.currentTarget.value)}
          />
        </VStack>

        <Button
          loadingText="Saving"
          isLoading={isPatchingCurrentUser}
          isDisabled={!isUsernameValid}
          w="full"
          onClick={onSetupProfileClick}
        >
          Save
        </Button>
      </VStack>
    </Box>
  )
}

export default EditProfileForm
