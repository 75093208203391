import { usePrivy } from '@privy-io/react-auth'
import { useMutation, useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { User, UserWithInviteCodes } from 'types/barn'
import { useQueryClient } from 'wagmi'

const baseUrl = process.env.REACT_APP_BARN_BASE_URL
if (!baseUrl) {
  throw new Error('REACT_APP_BARN_BASE_URL is not set')
}

export const getCurrentUser = async ({
  accessToken
}: {
  accessToken: string
}) => {
  const response = await axios.get<UserWithInviteCodes>(
    `${baseUrl}/v1/users/current-user`,
    {
      headers: { Authorization: `Bearer ${accessToken}` }
    }
  )
  return response.data
}

export const getCurrentUserQueryKey = ['getCurrentUser']
export const useGetCurrentUser = () => {
  const queryClient = useQueryClient()
  const { getAccessToken } = usePrivy()

  return useQuery<UserWithInviteCodes>({
    queryFn: async () => {
      const authToken = await getAccessToken()
      if (!authToken) throw new Error('No auth token')
      const user = await getCurrentUser({ accessToken: authToken })
      queryClient.setQueryData(getCurrentUserQueryKey, user)
      return user
    },
    queryKey: getCurrentUserQueryKey
  })
}

export const usePatchCurrentUser = () => {
  const queryClient = useQueryClient()
  const { getAccessToken } = usePrivy()

  return useMutation({
    mutationFn: async (
      user: Partial<{
        address: string
        description: string
        pfpUrl: string
        username: string
      }>
    ) => {
      const authToken = await getAccessToken()
      const response = await axios.patch<UserWithInviteCodes>(
        `${baseUrl}/v1/users`,
        user,
        {
          headers: { Authorization: `Bearer ${authToken}` }
        }
      )
      return response.data
    },
    onSuccess: (data) => {
      queryClient.setQueryData(getCurrentUserQueryKey, data)
    }
  })
}

export const useGetUser = ({ walletCode }: { walletCode: string }) => {
  return useQuery({
    queryFn: async () => {
      const response = await axios.get<User>(`${baseUrl}/v1/users`, {
        params: { walletCode }
      })
      return response.data
    },
    queryKey: getCurrentUserQueryKey
  })
}

export const usePatchValidateInvite = () => {
  const queryClient = useQueryClient()
  const { getAccessToken } = usePrivy()

  return useMutation({
    mutationFn: async (code: string) => {
      const authToken = await getAccessToken()
      const response = await axios.patch<boolean>(
        `${baseUrl}/v1/users/validate-invite`,
        { code },
        {
          headers: { Authorization: `Bearer ${authToken}` }
        }
      )
      return response.data
    },
    onSuccess: (isValid) => {
      if (isValid) {
        queryClient.invalidateQueries(getCurrentUserQueryKey)
      }
    }
  })
}
