import {
  Box,
  Heading,
  HStack,
  Image,
  SimpleGrid,
  Spacer,
  Text,
  VStack
} from '@chakra-ui/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'

export interface Activity {
  price: number
  subject: {
    creatorId: number
    name: string
  }
  timestamp: number
  type: 'mint' | 'buy' | 'sell'
  user: {
    id: number
    name: string
    pfpUrl: string
  }
}

interface ActivitiesListProps {
  activities: Activity[]
}

const ActivitiesList = ({ activities }: ActivitiesListProps) => {
  const navigate = useNavigate()

  return (
    <SimpleGrid spacing={6}>
      {activities.map((activity, i) => {
        let description: React.ReactElement
        switch (activity.type) {
          case 'buy':
            description = (
              <Text fontSize="sm">
                Bought{' '}
                <Box
                  as="b"
                  cursor="pointer"
                  onClick={() =>
                    navigate(`/creator/${activity.subject.creatorId}`)
                  }
                >
                  {activity.subject.name}
                </Box>{' '}
                {`pass for ${activity.price} ETH`}
              </Text>
            )
            break
          case 'mint':
            description = (
              <Text fontSize="sm">
                Minted{' '}
                <Box
                  as="b"
                  cursor="pointer"
                  onClick={() =>
                    navigate(`/creator/${activity.subject.creatorId}`)
                  }
                >
                  {activity.subject.name}
                </Box>
              </Text>
            )
            break
          case 'sell':
            description = (
              <Text fontSize="sm">
                Sold{' '}
                <Box
                  as="b"
                  cursor="pointer"
                  onClick={() =>
                    navigate(`/creator/${activity.subject.creatorId}`)
                  }
                >
                  {activity.subject.name}
                </Box>{' '}
                {`pass for ${activity.price} ETH`}
              </Text>
            )
            break
        }
        return (
          <Box
            key={i}
            pb={6}
            borderBottom="1px solid"
            borderBottomColor="gray.600"
          >
            <HStack>
              <Image
                boxSize="40px"
                src={activity.user.pfpUrl}
                borderRadius="full"
                cursor="pointer"
                onClick={() => navigate(`/creator/${activity.user.id}`)}
              />
              <VStack align="flex-start" w="full" spacing={1}>
                <HStack w="full">
                  <Heading
                    size="sm"
                    cursor="pointer"
                    onClick={() => navigate(`/creator/${activity.user.id}`)}
                  >
                    {activity.user.name}
                  </Heading>
                  <Spacer />
                  <Text fontSize="xs" textColor="gray.400">
                    {new Date(activity.timestamp).toLocaleString()}
                  </Text>
                </HStack>
                {description}
              </VStack>
            </HStack>
          </Box>
        )
      })}
    </SimpleGrid>
  )
}

export default ActivitiesList
