import {
  Box,
  Heading,
  HStack,
  Icon,
  Link,
  Text,
  VStack
} from '@chakra-ui/react'
import { ReactComponent as BellIcon } from 'assets/icons/bell.svg'
import { ReactComponent as HomeIcon } from 'assets/icons/home.svg'
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg'
import { ReactComponent as ProfileIcon } from 'assets/icons/user.svg'
import React from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'

const SideMenuItem = ({
  icon,
  isSelected,
  title,
  to
}: {
  icon: React.ReactNode
  isSelected: boolean
  title: string
  to: string
}) => {
  return (
    <Link as={RouterLink} to={to} w="full" _hover={{}}>
      <HStack
        px={4}
        py={2}
        _hover={{ bg: 'gray.600' }}
        borderRadius="full"
        cursor="pointer"
        bg={isSelected ? 'gray.600' : 'transparent'}
      >
        {icon}
        <Text fontWeight="bold">{title}</Text>
      </HStack>
    </Link>
  )
}

const WebSideMenu = () => {
  const location = useLocation()
  const pathname = location.pathname

  const items = [
    {
      icon: (
        <Icon
          as={HomeIcon}
          stroke={pathname === '/' ? 'white' : 'gray.400'}
          boxSize="24px"
        />
      ),
      isSelected: pathname === '/',
      title: 'Home',
      to: '/'
    },
    {
      icon: (
        <Icon
          as={BellIcon}
          fill={pathname === '/activity' ? 'white' : 'gray.400'}
          boxSize="24px"
        />
      ),
      isSelected: pathname === '/activity',
      title: 'Activity',
      to: '/activity'
    },
    {
      icon: (
        <Icon
          as={SearchIcon}
          fill={pathname === '/explore' ? 'white' : 'gray.400'}
          boxSize="24px"
        />
      ),
      isSelected: pathname === '/explore',
      title: 'Explore',
      to: '/explore'
    },
    {
      icon: (
        <Icon
          as={ProfileIcon}
          fill={pathname === '/profile' ? 'white' : 'gray.400'}
          boxSize="24px"
        />
      ),
      isSelected: pathname === '/profile',
      title: 'Profile',
      to: '/profile'
    }
  ]

  return (
    <Box
      w="240px"
      alignSelf="flex-start"
      pos="sticky"
      top="0"
      zIndex="sticky"
      pt={4}
      flexShrink={0}
    >
      <VStack align="flex-start" spacing={10}>
        <Heading>friend.art</Heading>
        <VStack spacing={4} w="full">
          {items.map((item) => (
            <SideMenuItem key={item.title} {...item} />
          ))}
        </VStack>
      </VStack>
    </Box>
  )
}

export default WebSideMenu
