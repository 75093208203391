import { faFactoryDropAddress } from 'config'
import { getAddress, parseAbi } from 'viem'
import { useContractWrite, usePublicClient } from 'wagmi'

interface UseCreateCollectionProps {
  collectionName: string
  collectionSymbol: string
}

const useCreateCollection = ({
  collectionName,
  collectionSymbol
}: UseCreateCollectionProps) => {
  const publicClient = usePublicClient()

  const { writeAsync } = useContractWrite({
    abi: parseAbi([
      'function createDropCollection(string calldata collectionName, string calldata collectionSymbol) public returns (address)'
    ]),
    address: getAddress(faFactoryDropAddress),
    args: [collectionName, collectionSymbol],
    functionName: 'createDropCollection'
  })

  const createCollection = async () => {
    const result = await writeAsync()
    const receipt = await publicClient.waitForTransactionReceipt({
      hash: result.hash
    })
    return receipt
  }

  return {
    createCollection
  }
}

export default useCreateCollection
