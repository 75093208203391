import {
  Box,
  Button,
  Heading,
  HStack,
  Image,
  SimpleGrid,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack
} from '@chakra-ui/react'
import ActivitiesList from 'components/ActivitiesList'
import CreatorDropsList from 'components/CreatorDropsList'
import CreatorPassGrid from 'components/CreatorPassesGrid'
import { BackButton } from 'components/NavigationBar'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { activities, creatorDrops, passes } from 'stub'

const Creator = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  // TODO: get from API with id
  const pass = passes[0]
  const isOwnedByUser = pass.userOwnedQuantity > 0

  const onBuySellPassClick = () => {
    navigate(`/creator/${id}/trade`)
  }

  return (
    <VStack minH="100svh" spacing={6} pos="relative">
      <Box pos="absolute" top={4} left={0} zIndex="popover">
        <BackButton />
      </Box>

      <VStack pt={4} px={4}>
        <Image src={pass.pfpUrl} boxSize="70px" borderRadius="full" />
        <Heading size="lg">{pass.name}</Heading>
      </VStack>

      <Box px={4} w="full">
        <VStack
          border="1px solid"
          borderColor="gray.600"
          p={4}
          borderRadius="lg"
        >
          <HStack
            w="full"
            justifyContent={isOwnedByUser ? 'flex-start' : 'center'}
          >
            <Heading size="md">Collector Pass</Heading>
            {isOwnedByUser && <Spacer />}
            {isOwnedByUser && (
              <Text fontSize="xs" textColor="gray.400">
                You have:{' '}
                <Box as="span" textColor="white">
                  {pass.userOwnedQuantity}
                </Box>
              </Text>
            )}
          </HStack>
          {isOwnedByUser ? (
            <SimpleGrid columns={2} w="full" spacing={4} mt={4}>
              <Button variant="outline" onClick={onBuySellPassClick}>
                Sell
              </Button>
              <Button onClick={onBuySellPassClick}>Buy</Button>
            </SimpleGrid>
          ) : (
            <>
              <Text textColor="gray.400" fontSize="sm">
                Gain access to the drops of this creator
              </Text>
              <Button
                mt={2}
                w="full"
                onClick={onBuySellPassClick}
              >{`Buy for ${pass.passPrice} ETH`}</Button>
            </>
          )}
        </VStack>
      </Box>

      <Box bg="gray.700" w="full">
        <Tabs variant="solid-rounded" p={4} isFitted>
          <TabList w="full">
            <Tab>Drops</Tab>
            <Tab>Activity</Tab>
            <Tab>Collectors</Tab>
          </TabList>

          <TabPanels pt={2}>
            <TabPanel>
              <CreatorDropsList drops={creatorDrops} />
            </TabPanel>
            <TabPanel>
              <ActivitiesList activities={activities} />
            </TabPanel>
            <TabPanel>
              <CreatorPassGrid passes={passes} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </VStack>
  )
}

export default Creator
