import { Heading, Spacer, VStack } from '@chakra-ui/react'
import { usePrivy } from '@privy-io/react-auth'
import EditProfileForm from 'components/EditProfileForm'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const SetupProfile = () => {
  const { user } = usePrivy()
  const navigate = useNavigate()

  const socialAccount = user?.linkedAccounts.find((account) => {
    return account.type === 'google_oauth' || account.type === 'twitter_oauth'
  })
  let defaultUsername = ''
  switch (socialAccount?.type) {
    case 'google_oauth':
      defaultUsername = user?.google?.name || ''
      break
    case 'twitter_oauth':
      defaultUsername = user?.twitter?.name || ''
      break
    default:
      break
  }

  return (
    <VStack minH="calc(100vh - 56px)">
      <Heading pt={8}>Setup your profile</Heading>

      <Spacer />

      <EditProfileForm
        defaultUsername={defaultUsername}
        onEditProfileSuccess={() => navigate('/login/fund')}
      />
    </VStack>
  )
}

export default SetupProfile
