import { defineStyleConfig } from '@chakra-ui/react'

const solidButtonBackground = {
  bgGradient: 'linear(to-r, #05da9a, #c6c90f)'
}

const outlineButtonProps = {
  '> *': {
    background: `linear-gradient(135deg, #05da9a, #c6c90f) border-box`,
    backgroundClip: 'text',
    textFillColor: 'transparent'
  },
  background: `linear-gradient(#000000, #000000) padding-box, linear-gradient(135deg, #05da9a, #c6c90f) border-box`,
  border: '2px solid',
  borderColor: 'transparent',
  borderRadius: 'full'
}

export const buttonsTheme = defineStyleConfig({
  baseStyle: {
    _active: {},
    _focus: {},
    _hover: {},
    borderRadius: 'full',
    fontWeight: 'bold'
  },
  defaultProps: {
    size: 'md',
    variant: 'solid'
  },
  sizes: {
    md: {
      fontSize: 'md',
      px: 6,
      py: 4
    },
    sm: {
      fontSize: 'sm',
      px: 4,
      py: 2
    }
  },
  variants: {
    'gray-outline': {
      _active: {
        border: '2px solid',
        borderColor: 'gray.500'
      },
      _focus: {
        border: '2px solid',
        borderColor: 'gray.500'
      },
      _hover: {
        border: '2px solid',
        borderColor: 'gray.500'
      },
      border: '2px solid',
      borderColor: 'gray.500',
      color: 'white'
    },
    outline: {
      ...outlineButtonProps,
      _active: {
        ...outlineButtonProps
      },
      _focus: {
        ...outlineButtonProps
      },
      _hover: {
        ...outlineButtonProps
      },
      color: 'white'
    },
    solid: {
      color: 'black',
      ...solidButtonBackground,
      _active: {
        ...solidButtonBackground
      },
      _focus: {
        ...solidButtonBackground
      },
      _hover: {
        ...solidButtonBackground
      }
    }
  }
})
