import { Button, Heading, HStack, Spacer, Text, VStack } from '@chakra-ui/react'
import {
  AppleOAuthWithMetadata,
  EmailWithMetadata,
  GoogleOAuthWithMetadata,
  usePrivy
} from '@privy-io/react-auth'
import { NavigationBar } from 'components/NavigationBar'
import React from 'react'

const Row = ({
  isButtonDisabled,
  onButtonClick,
  title,
  value
}: {
  isButtonDisabled: boolean
  onButtonClick: () => void
  title: string
  value?: string
}) => {
  const isLinked = value !== undefined
  return (
    <HStack
      w="full"
      borderBottom="1px solid"
      borderBottomColor="gray.600"
      p={4}
    >
      <VStack align="flex-start" spacing={1}>
        <Heading size="sm">{title}</Heading>
        <Text>{value}</Text>
      </VStack>
      <Spacer />
      <Button
        variant="gray-outline"
        size="sm"
        isDisabled={isButtonDisabled}
        onClick={onButtonClick}
      >
        {isLinked ? 'Unlink' : 'Link'}
      </Button>
    </HStack>
  )
}

const ManageLoginMethods = () => {
  const {
    linkApple,
    linkEmail,
    linkGoogle,
    unlinkApple,
    unlinkEmail,
    unlinkGoogle,
    user
  } = usePrivy()

  console.log(user)

  const googleAccount = user?.linkedAccounts.find(
    (account) => account.type === 'google_oauth'
  ) as GoogleOAuthWithMetadata | undefined
  const appleAccount = user?.linkedAccounts.find(
    (account) => account.type === 'apple_oauth'
  ) as AppleOAuthWithMetadata | undefined
  const emailAccount = user?.linkedAccounts.find(
    (account) => account.type === 'email'
  ) as EmailWithMetadata | undefined

  const nbOfLinkedAccounts =
    user?.linkedAccounts.filter((account) => account.type !== 'wallet')
      .length || 0

  return (
    <VStack spacing={0}>
      <NavigationBar title="Manage login methods" showBackButton />

      <Row
        title="Email"
        value={user?.email?.address}
        isButtonDisabled={!!emailAccount && nbOfLinkedAccounts === 1}
        onButtonClick={() => {
          emailAccount ? unlinkEmail(emailAccount.address) : linkEmail()
        }}
      />
      <Row
        title="Google"
        value={user?.google?.email}
        isButtonDisabled={!!googleAccount && nbOfLinkedAccounts === 1}
        onButtonClick={() => {
          googleAccount ? unlinkGoogle(googleAccount.subject) : linkGoogle()
        }}
      />
      <Row
        title="Apple"
        value={user?.apple?.email}
        isButtonDisabled={!!appleAccount && nbOfLinkedAccounts === 1}
        onButtonClick={() => {
          appleAccount ? unlinkApple(appleAccount.subject) : linkApple()
        }}
      />
      <Text p={4} fontSize="sm" textColor="gray.400">
        You must have at least one account linked to your friend.art account.
      </Text>
    </VStack>
  )
}

export default ManageLoginMethods
