import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Text,
  useMediaQuery,
  VStack
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { UserWithInviteCodes } from 'types/barn'

type State = 'initial' | 'credit_card' | 'crypto_wallet'

interface AddFundsDrawerProps {
  isOpen: boolean
  onClose: () => void
  user?: UserWithInviteCodes
}

const AddFundsDrawer = ({ isOpen, onClose, user }: AddFundsDrawerProps) => {
  const [isLargerThanXL] = useMediaQuery('(min-width: 1280px)')
  const [state, setState] = useState<State>('initial')

  let title: string
  switch (state) {
    case 'initial':
    case 'credit_card':
      title = 'How would you like to fund your account?'
      break
    case 'crypto_wallet':
      title = 'Deposit from another wallet'
      break
  }

  // reset state on close
  useEffect(() => {
    if (!isOpen) {
      setState('initial')
    }
  }, [isOpen])

  return (
    <Drawer
      placement={isLargerThanXL ? 'right' : 'bottom'}
      size={isLargerThanXL ? 'sm' : undefined}
      onClose={onClose}
      isOpen={isOpen}
    >
      <DrawerOverlay />
      <DrawerContent overflow="scroll" maxH="100svh">
        <DrawerCloseButton pt={4} />
        <DrawerHeader p={4} borderBottomWidth="1px">
          {title}
        </DrawerHeader>
        <DrawerBody p="16px 16px max(env(safe-area-inset-bottom, 16px), 16px) 16px">
          {state === 'initial' && (
            <VStack spacing={4} w="full">
              <Button
                variant="unstyled"
                w="full"
                textAlign="left"
                h="60px"
                bg="gray.600"
                borderRadius="full"
                pl={6}
              >
                With credit card
              </Button>
              <Button
                variant="unstyled"
                w="full"
                textAlign="left"
                h="60px"
                bg="gray.600"
                borderRadius="full"
                pl={6}
                onClick={() => setState('crypto_wallet')}
              >
                From crypto wallet
              </Button>
            </VStack>
          )}

          {state === 'crypto_wallet' && (
            <VStack align="flex-start" spacing={4}>
              <Text>
                Open the browser you use with your other wallet and go to:
              </Text>
              <Text fontWeight="bold" textColor="accent">
                friend.art/deposit/{user?.walletId}
              </Text>
              <Button variant="gray-outline" w="full" onClick={onClose}>
                Done
              </Button>
            </VStack>
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export default AddFundsDrawer
