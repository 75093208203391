import { extendTheme, ThemeConfig } from '@chakra-ui/react'
import { buttonsTheme } from 'theme/button'
import { tabsTheme } from 'theme/tabs'

const config: ThemeConfig = {
  initialColorMode: 'dark',
  useSystemColorMode: false
}

const theme = extendTheme({
  colors: {
    accent: '#4ef6a5',
    gray: {
      400: '#9a9a9a',
      500: '#414141',
      600: '#252525',
      700: '#1b1b1b'
    }
  },
  components: {
    Button: buttonsTheme,
    Tabs: tabsTheme
  },
  config,
  styles: {
    global: () => ({
      'html, body': {
        background: 'black'
      }
    })
  }
})

export default theme
