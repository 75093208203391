import {
  Button,
  Center,
  Flex,
  Grid,
  Heading,
  Image,
  SimpleGrid,
  Spacer,
  Text,
  VStack
} from '@chakra-ui/react'
import { useLogin, usePrivy } from '@privy-io/react-auth'
import { useQueryClient } from '@tanstack/react-query'
import CollectIcon from 'assets/collect.svg'
import EarnIcon from 'assets/earn.svg'
import AppLogo from 'assets/fa-logo.svg'
import PassIcon from 'assets/pass.svg'
import { getCurrentUser, getCurrentUserQueryKey } from 'hooks/useBarn'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const FeatureView = ({
  iconSrc,
  title
}: {
  iconSrc: string
  title: string
}) => {
  return (
    <Flex
      w="full"
      flexDir="row"
      align="center"
      gap={4}
      bg="gray.700"
      py={4}
      px={6}
      borderRadius="full"
    >
      <Image src={iconSrc} boxSize="30px" />
      <Text textColor="white" fontSize="md" fontWeight="bold">
        {title}
      </Text>
    </Flex>
  )
}

const Login = () => {
  const navigate = useNavigate()
  const { authenticated, getAccessToken } = usePrivy()
  const queryClient = useQueryClient()

  const { login } = useLogin({
    onComplete: async (user, isNewUser, wasAlreadyAuthenticated) => {
      console.log(user, isNewUser, wasAlreadyAuthenticated)

      const authToken = await getAccessToken()
      const barnUser = await getCurrentUser({ accessToken: authToken || '' })

      await queryClient.invalidateQueries(getCurrentUserQueryKey)

      switch (barnUser.status) {
        case 'pending':
          navigate('/login/enter-code')
          break
        case 'verified':
          navigate('/')
          break
      }
    },
    onError: (error) => {
      // TODO: handle error
      console.log(error)
    }
  })

  const onLoginClick = () => {
    if (authenticated) {
      navigate('/')
    } else {
      login()
    }
  }

  return (
    <Center textAlign="center" fontSize="xl" bg="black" minH="100svh">
      <Grid minH="90svh" p={4} w="full">
        <VStack spacing={8} w="full">
          <VStack spacing={2}>
            <Image src={AppLogo} boxSize={{ base: '80px', md: '100px' }} />
            <Heading textColor="white">friend.art</Heading>
            <Text textColor="gray.400" fontSize="sm">
              Friend Art is a network for digital collectors
            </Text>
          </VStack>
          <Spacer />
          <SimpleGrid w="full" spacing={6}>
            <FeatureView
              title="Collect digital artworks"
              iconSrc={CollectIcon}
            />
            <FeatureView title="Trade collector passes" iconSrc={PassIcon} />
            <FeatureView title="Earn royalties" iconSrc={EarnIcon} />
          </SimpleGrid>
          <Spacer />
          <Button size="lg" borderRadius="full" onClick={onLoginClick} w="full">
            Sign In
          </Button>
        </VStack>
      </Grid>
    </Center>
  )
}

export default Login
