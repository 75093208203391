import {
  Button,
  Heading,
  Skeleton,
  TabPanel,
  Text,
  VStack
} from '@chakra-ui/react'
import NumericalInput from 'components/NumericalInput'
import useBuyShares from 'hooks/useBuyShares'
import useSharesBalance from 'hooks/useSharesBalance'
import React, { useState } from 'react'
import { formatAmount } from 'utils/format'
import { usePublicClient } from 'wagmi'

const BuySharesPanel = ({ creatorAddress }: { creatorAddress: string }) => {
  const [amount, setAmount] = useState('')

  const publicClient = usePublicClient()

  const {
    data: sharesBalance,
    isLoading: isLoadingSharesBalance,
    refetch: refetchSharesBalance
  } = useSharesBalance({
    subjectAddress: creatorAddress
  })

  const { buyPrice, writeAsync } = useBuyShares({
    amount: Number(amount),
    subjectAddress: creatorAddress
  })

  const onBuyClick = async () => {
    if (!writeAsync) return
    const result = await writeAsync()
    const receipt = await publicClient.waitForTransactionReceipt({
      hash: result.hash
    })
    if (receipt.status === 'success') {
      setAmount('')
      refetchSharesBalance()
    }
  }

  return (
    <TabPanel>
      <VStack spacing={10} mt={10}>
        <VStack spacing={1} w="full">
          <Skeleton isLoaded={!isLoadingSharesBalance}>
            <Text fontSize="sm" textColor="gray.400">
              You have {Number(sharesBalance || 0)} creator passes
            </Text>
          </Skeleton>

          <NumericalInput
            value={amount}
            onValueChange={setAmount}
            placeholder="0"
            inputType="integer"
          />
        </VStack>

        <VStack spacing={1}>
          <Text fontSize="sm" textColor="gray.400">
            You will pay:
          </Text>
          <Heading size="md">
            {buyPrice ? formatAmount(buyPrice, 18) : '--'} ETH
          </Heading>
        </VStack>

        <Button
          w="full"
          size="lg"
          isDisabled={!amount || !writeAsync}
          onClick={onBuyClick}
        >
          Buy
        </Button>
      </VStack>
    </TabPanel>
  )
}

export default BuySharesPanel
