import { faCoreAddress } from 'config'
import { faCoreAbi } from 'constants/abi'
import { getAddress } from 'viem'
import {
  useContractRead,
  useContractWrite,
  usePrepareContractWrite
} from 'wagmi'

interface UseSellSharesProps {
  amount: number
  enabled: boolean
  subjectAddress: string
}

export const useSellShares = ({
  amount,
  enabled,
  subjectAddress
}: UseSellSharesProps) => {
  const { data: sellPrice } = useContractRead({
    abi: faCoreAbi,
    address: faCoreAddress,
    args: [getAddress(subjectAddress), BigInt(amount)],
    enabled: amount > 0,
    functionName: 'getSellPriceAfterFee'
  })

  const { config } = usePrepareContractWrite({
    abi: faCoreAbi,
    address: faCoreAddress,
    args: [getAddress(subjectAddress), BigInt(amount)],
    enabled: enabled && amount > 0,
    functionName: 'sellPasses'
  })

  const { writeAsync } = useContractWrite(config)

  return {
    sellPrice,
    writeAsync
  }
}

export default useSellShares
