import { ChevronRightIcon } from '@chakra-ui/icons'
import {
  Box,
  Center,
  Heading,
  HStack,
  Image,
  Spacer,
  Spinner,
  Text,
  VStack
} from '@chakra-ui/react'
import { usePrivy } from '@privy-io/react-auth'
import { usePrivyWagmi } from '@privy-io/wagmi-connector'
import ChatIcon from 'assets/chat.svg'
import CollectIcon from 'assets/collect.svg'
import ProfileIcon from 'assets/user-gradient.svg'
import WalletIcon from 'assets/wallet.svg'
import { ProfileNavigationBar } from 'components/NavigationBar'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { formatAmountString } from 'utils/format'
import { getAddress } from 'viem'
import { useBalance } from 'wagmi'

const ProfileMenuItem = ({
  iconSrc,
  title,
  to,
  value
}: {
  iconSrc: string
  title: string
  to: string
  value?: string
}) => {
  const navigate = useNavigate()

  return (
    <Box
      border="1px solid"
      borderColor="gray.600"
      borderRadius="lg"
      w="full"
      pr={4}
      py={6}
      pl={6}
      cursor="pointer"
      _hover={{ bg: 'gray.700' }}
      onClick={() => navigate(to)}
    >
      <HStack spacing={3}>
        <Image src={iconSrc} boxSize="30px" />
        <Heading size="sm">{title}</Heading>
        <Spacer />
        {value ? (
          <Text fontSize="sm" textColor="gray.400">
            {value}
          </Text>
        ) : null}
        <ChevronRightIcon color="gray.400" />
      </HStack>
    </Box>
  )
}

const Profile = () => {
  const { ready } = usePrivy()
  const userId = 1 // TODO: get from backend? or use address?

  // get balance
  const { wallet: activeWallet } = usePrivyWagmi()
  const { data: balance } = useBalance({
    address: activeWallet ? getAddress(activeWallet.address) : undefined
  })

  return (
    <Box pb="56px" minH="100vh">
      <ProfileNavigationBar />
      {!ready ? (
        <Center minH="calc(100vh - 110px)">
          <Spinner />
        </Center>
      ) : (
        <VStack p={4} spacing={4}>
          <ProfileMenuItem
            title="Profile"
            to={`/creator/${userId}`}
            iconSrc={ProfileIcon}
          />
          <ProfileMenuItem
            title="Wallet"
            to="/profile/wallet"
            iconSrc={WalletIcon}
            value={
              balance
                ? `${formatAmountString(balance.formatted)} ETH`
                : undefined
            }
          />
          <ProfileMenuItem
            title="Portfolio"
            to="/profile/portfolio"
            iconSrc={CollectIcon}
          />
          <ProfileMenuItem
            title="Drops"
            to="/collections"
            iconSrc={CollectIcon}
          />
          <ProfileMenuItem
            title="Invite Codes"
            to="/profile/invite-codes"
            iconSrc={ChatIcon}
          />
        </VStack>
      )}
    </Box>
  )
}

export default Profile
