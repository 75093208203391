import {
  CloseButton,
  Input,
  InputGroup,
  InputGroupProps,
  InputLeftElement,
  InputProps,
  InputRightElement
} from '@chakra-ui/react'
import React from 'react'
import { FaSearch } from 'react-icons/fa'

interface SearchBarProps {
  inputGroupProps?: InputGroupProps
  inputProps?: InputProps
  onClearClick?: () => void
}

const SearchBar = ({
  inputGroupProps,
  inputProps,
  onClearClick
}: SearchBarProps) => {
  return (
    <InputGroup {...inputGroupProps}>
      <InputLeftElement>
        <FaSearch color="white" />
      </InputLeftElement>
      <Input
        type="text"
        borderRadius="full"
        _placeholder={{ textColor: 'gray.400' }}
        {...inputProps}
      />
      {onClearClick ? (
        <InputRightElement>
          <CloseButton borderRadius="full" onClick={onClearClick} />
        </InputRightElement>
      ) : null}
    </InputGroup>
  )
}

export default SearchBar
