import { Box, Button, Heading, Input, Text, VStack } from '@chakra-ui/react'
import { usePatchValidateInvite } from 'hooks/useBarn'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const EnterInviteCode = () => {
  const navigate = useNavigate()

  const {
    isError,
    isLoading,
    mutateAsync: validateInvite
  } = usePatchValidateInvite()

  const [inviteCode, setInviteCode] = React.useState('')
  const isInviteCodeValid = inviteCode.length > 0

  const onSubmitCodeClick = async () => {
    try {
      const isValid = await validateInvite(inviteCode.trim())
      if (isValid) {
        navigate('/login/setup-profile')
      }
    } catch {}
  }

  return (
    <VStack h="100vh" spacing={0}>
      <Box bg="blue.900" h="full" w="full" />

      <VStack
        w="full"
        spacing={8}
        bg="gray.700"
        borderTopRadius="2xl"
        h="full"
        mt={-4}
        p={4}
      >
        <VStack>
          <Heading>Do you have an invite code?</Heading>
          <Text fontSize="sm" textColor="gray.400">
            To create your account you need enter your invite code below
          </Text>
        </VStack>
        <VStack w="full" align="flex-start">
          <Text fontSize="sm" textColor="gray.400">
            Enter invite code:
          </Text>
          <Input
            value={inviteCode}
            isInvalid={isError}
            onChange={(e) => {
              setInviteCode(e.currentTarget.value)
            }}
          />
          {isError ? (
            <Text textColor="red.400" fontSize="sm" textAlign="center" w="full">
              Invalid invite code
            </Text>
          ) : null}
        </VStack>
        <Button
          isDisabled={!isInviteCodeValid}
          isLoading={isLoading}
          loadingText="Validating"
          w="full"
          onClick={onSubmitCodeClick}
        >
          Submit
        </Button>
      </VStack>
    </VStack>
  )
}

export default EnterInviteCode
