import '@rainbow-me/rainbowkit/styles.css'

import { ExternalLinkIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Center,
  Heading,
  HStack,
  InputGroup,
  InputRightElement,
  Link,
  Spacer,
  Spinner,
  Text,
  VStack
} from '@chakra-ui/react'
import { useConnectModal } from '@rainbow-me/rainbowkit'
import NumericalInput from 'components/NumericalInput'
import { appChain } from 'config'
import { useGetUser } from 'hooks/useBarn'
import useSendETH from 'hooks/useSendETH'
import React, { useState } from 'react'
import { useDebounce } from 'use-debounce'
import { formatAmountString, shortenString } from 'utils/format'
import { parseEther } from 'viem'
import { useAccount, useBalance, useNetwork, useSwitchNetwork } from 'wagmi'

const DepositFromWeb = () => {
  // get user from backend
  const params = window.location.pathname.split('/')
  const walletCode = params[params.length - 1]
  const {
    data: user,
    isError: isGetUserError,
    isLoading: isLoadingUser
  } = useGetUser({
    walletCode: walletCode || ''
  })

  // connect wallet via rainbowkit
  const { openConnectModal } = useConnectModal()

  // web wallet used to deposit funds
  const { address: account } = useAccount()
  const { data: balance } = useBalance({
    address: account
  })

  // amount
  const [amount, setAmount] = useState('')
  const amountValue = parseEther(amount)
  const [debouncedAmount] = useDebounce(amountValue, 500)
  const isAmountGreaterThanBalance = balance && amountValue > balance.value
  const isAmountValid =
    amount !== '' && parseFloat(amount) > 0 && !isAmountGreaterThanBalance

  // send ETH
  const { chain } = useNetwork()
  const { switchNetworkAsync } = useSwitchNetwork({
    chainId: appChain.id
  })
  const { isLoading, receipt, sendTransaction } = useSendETH({
    amount: debouncedAmount ? debouncedAmount : undefined,
    enabled: isAmountValid,
    to: user?.address
  })
  const sendETH = async () => {
    if (chain && chain.id !== appChain.id) {
      await switchNetworkAsync?.()
    }
    await sendTransaction?.()
  }

  if (isGetUserError) {
    return (
      <Center minH="100vh">
        <Text>User not found {walletCode}</Text>
      </Center>
    )
  }

  if (isLoadingUser || !user) {
    return (
      <Center minH="100vh">
        <Spinner />
      </Center>
    )
  }

  return (
    <Center pt="100px">
      <VStack maxW="400px" w="full" align="center" spacing={12}>
        <Heading size="lg">Add funds to your friend.art wallet</Heading>

        <HStack w="full" align="center" spacing={4}>
          <Box boxSize="56px" bg="gray.400" borderRadius="full" />
          <VStack align="flex-start" spacing={1}>
            <Heading size="md">{user.username}</Heading>
            <Text textColor="gray.400">{shortenString(user.address)}</Text>
          </VStack>
        </HStack>

        <VStack w="full" align="flex-start">
          <HStack w="full">
            {account ? (
              <Heading size="sm">From: {shortenString(account)}</Heading>
            ) : null}
            <Spacer />
            {balance ? (
              <Button
                variant="ghost"
                size="xs"
                onClick={() => setAmount(balance.formatted)}
              >
                Balance: {formatAmountString(balance.formatted)}
              </Button>
            ) : null}
          </HStack>
          <InputGroup>
            <NumericalInput
              isInvalid={!isAmountValid && amount !== ''}
              placeholder="0.0"
              value={amount}
              onValueChange={setAmount}
            />
            <InputRightElement pr={2} w="fit-content">
              ETH
            </InputRightElement>
          </InputGroup>
        </VStack>

        {account ? (
          <Button
            w="full"
            isDisabled={!isAmountValid || !sendTransaction}
            isLoading={isLoading}
            loadingText="Depositing..."
            onClick={() => sendETH()}
          >
            {isAmountGreaterThanBalance ? 'Not enough ETH' : 'Deposit'}
          </Button>
        ) : (
          <Button w="full" onClick={openConnectModal}>
            Connect Wallet
          </Button>
        )}

        {receipt ? (
          <Center w="full">
            <VStack align="flex-start" w="full" spacing={1}>
              <Text>
                {receipt.status === 'success'
                  ? 'Transaction successful!'
                  : 'Transaction failed'}
              </Text>
              <Link
                isExternal
                href={`https://basescan.org/tx/${receipt.transactionHash}`}
                color="gray.400"
              >
                <HStack>
                  <Text>View transaction on Explorer</Text>
                  <ExternalLinkIcon />
                </HStack>
              </Link>
            </VStack>
          </Center>
        ) : null}
      </VStack>
    </Center>
  )
}

export default DepositFromWeb
