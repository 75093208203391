import { Box } from '@chakra-ui/react'
import CreatorDropsList from 'components/CreatorDropsList'
import { NavigationBar } from 'components/NavigationBar'
import React from 'react'
import { creatorDrops } from 'stub'

const Home = () => {
  return (
    <Box>
      <NavigationBar title="Home" />
      <Box px={4} py={6} minH="100vh" bg="gray.700" borderTopRadius="40px">
        <CreatorDropsList drops={creatorDrops} />
      </Box>
    </Box>
  )
}

export default Home
