import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Spacer,
  Text,
  useMediaQuery,
  VStack
} from '@chakra-ui/react'
import { usePrivyWagmi } from '@privy-io/wagmi-connector'
import useSendETH from 'hooks/useSendETH'
import React, { useState } from 'react'
import { formatAmountString } from 'utils/format'
import { getAddress, isAddress, parseEther } from 'viem'
import { useBalance } from 'wagmi'

import NumericalInput from './NumericalInput'

interface WithdrawFundsDrawerProps {
  isOpen: boolean
  onClose: () => void
}

const WithdrawFundsDrawer = ({ isOpen, onClose }: WithdrawFundsDrawerProps) => {
  const [isLargerThanXL] = useMediaQuery('(min-width: 1280px)')

  const { wallet } = usePrivyWagmi()
  const { data: balance } = useBalance({
    address: wallet ? getAddress(wallet.address) : undefined
  })

  const [recipient, setRecipient] = useState('')
  const isRecipientValid = isAddress(recipient)

  const [amount, setAmount] = useState('')
  const amountValue = parseEther(amount)
  const isAmountValid =
    (amount !== '' &&
      parseFloat(amount) > 0 &&
      balance &&
      amountValue <= balance.value) ||
    false

  const { isLoading, sendTransaction } = useSendETH({
    amount: amount ? parseEther(amount) : undefined,
    enabled: isRecipientValid && isAmountValid,
    to: recipient
  })

  const onTransferClick = async () => {
    await sendTransaction?.()
    onClose()
  }

  return (
    <Drawer
      placement={isLargerThanXL ? 'right' : 'bottom'}
      size={isLargerThanXL ? 'sm' : undefined}
      onClose={onClose}
      isOpen={isOpen}
    >
      <DrawerOverlay />
      <DrawerContent overflow="scroll" maxH="100svh">
        <DrawerCloseButton pt={4} />
        <DrawerHeader p={4} borderBottomWidth="1px">
          Withdraw ETH
        </DrawerHeader>
        <DrawerBody p="16px 16px max(env(safe-area-inset-bottom, 16px), 16px) 16px">
          <VStack spacing={8} align="flex-start">
            <Text>Transfer ETH to another wallet on Base.</Text>

            <VStack spacing={4} w="full">
              <VStack spacing={1} align="flex-start" w="full">
                <Text fontSize="sm">Recipient:</Text>
                <Input
                  placeholder="0x"
                  value={recipient}
                  isInvalid={recipient !== '' && !isRecipientValid}
                  onChange={(e) => setRecipient(e.currentTarget.value)}
                />
              </VStack>
              <VStack spacing={1} align="flex-start" w="full">
                <HStack w="full">
                  <Text fontSize="sm">Amount:</Text>
                  <Spacer />
                  {balance ? (
                    <Button
                      size="xs"
                      variant="ghost"
                      onClick={() => setAmount(balance.formatted)}
                    >
                      Balance: {formatAmountString(balance.formatted)}{' '}
                      {balance.symbol}
                    </Button>
                  ) : null}
                </HStack>
                <InputGroup>
                  <NumericalInput
                    placeholder="0.0"
                    value={amount}
                    onValueChange={setAmount}
                    isInvalid={amount !== '' && !isAmountValid}
                  />
                  <InputRightElement w="fit-content" pr={2}>
                    ETH
                  </InputRightElement>
                </InputGroup>
              </VStack>
            </VStack>

            <Button
              w="full"
              isDisabled={
                !isRecipientValid || !isAmountValid || !sendTransaction
              }
              isLoading={isLoading}
              loadingText="Transferring..."
              onClick={onTransferClick}
            >
              Transfer
            </Button>
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export default WithdrawFundsDrawer
