import { getAddress, parseAbi } from 'viem'
import { useContractWrite, usePublicClient } from 'wagmi'

interface UseCreateDropProps {
  collectionAddress: string
  startTime: number
  tiersMetadata: string[]
  tiersMinHolding: number[]
}

const useCreateDrop = ({
  collectionAddress,
  startTime,
  tiersMetadata,
  tiersMinHolding
}: UseCreateDropProps) => {
  const publicClient = usePublicClient()

  const { writeAsync } = useContractWrite({
    abi: parseAbi([
      'function createDrop(uint256 startTime, uint256[] calldata tiersMinHolding, string[] calldata tiersMetadata) external'
    ]),
    address: getAddress(collectionAddress),
    args: [
      BigInt(startTime),
      tiersMinHolding.map((val) => BigInt(val)),
      tiersMetadata
    ],
    functionName: 'createDrop'
  })

  const createDrop = async () => {
    const result = await writeAsync()
    const receipt = await publicClient.waitForTransactionReceipt({
      hash: result.hash
    })
    return receipt
  }

  return {
    createDrop
  }
}

export default useCreateDrop
