import { Box } from '@chakra-ui/react'
import ActivitiesList from 'components/ActivitiesList'
import { NavigationBar } from 'components/NavigationBar'
import React from 'react'
import { activities } from 'stub'

const Activity = () => {
  return (
    <Box>
      <NavigationBar title="Activity" />
      <Box px={4} pb="50px" minH="100svh">
        <ActivitiesList activities={activities} />
      </Box>
    </Box>
  )
}

export default Activity
