import {
  Box,
  HStack,
  IconButton,
  Image,
  Spacer,
  Text,
  VStack
} from '@chakra-ui/react'
import CopyIcon from 'assets/copy.svg'
import { NavigationBar } from 'components/NavigationBar'
import React from 'react'
import { UserWithInviteCodes } from 'types/barn'

interface InviteCodesProps {
  user?: UserWithInviteCodes
}

const InviteCodes = ({ user }: InviteCodesProps) => {
  if (!user) return null

  return (
    <Box>
      <NavigationBar title="Invite Codes" showBackButton />

      <VStack
        w="full"
        bg="gray.700"
        minH="calc(100svh - 56px)"
        p={4}
        spacing={6}
        align="flex-start"
      >
        {user.inviteCodes.map(({ code, status }) => (
          <HStack w="full" key={code}>
            <Text
              key={code}
              fontSize="md"
              fontWeight="bold"
              textDecor={status === 'used' ? 'line-through' : undefined}
            >
              {code}
            </Text>
            <Spacer />
            {status === 'valid' && (
              <IconButton
                bg="gray.500"
                variant="ghost"
                aria-label="copy invite code"
                boxSize="40px"
                icon={<Image src={CopyIcon} />}
              />
            )}
          </HStack>
        ))}
      </VStack>
    </Box>
  )
}

export default InviteCodes
