import { Box, VStack } from '@chakra-ui/react'
import CreatorPassGrid from 'components/CreatorPassesGrid'
import { NavigationBarContainer } from 'components/NavigationBar'
import SearchBar from 'components/SearchBar'
import React from 'react'
import { passesWithItemsPreview } from 'stub'

const Explore = () => {
  return (
    <VStack minH="100svh">
      <NavigationBarContainer maxW="620px">
        <SearchBar
          inputGroupProps={{
            mx: 4
          }}
          inputProps={{
            placeholder: 'Search by username',
            value: ''
          }}
        />
      </NavigationBarContainer>

      <Box w="full" p={4}>
        <CreatorPassGrid passes={passesWithItemsPreview} />
      </Box>
    </VStack>
  )
}

export default Explore
