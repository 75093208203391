import { CopyIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Center,
  Heading,
  HStack,
  IconButton,
  SimpleGrid,
  Spacer,
  Spinner,
  Text,
  useDisclosure,
  VStack
} from '@chakra-ui/react'
import { usePrivy } from '@privy-io/react-auth'
import { usePrivyWagmi } from '@privy-io/wagmi-connector'
import AddFundsDrawer from 'components/AddFundsDrawer'
import { NavigationBar } from 'components/NavigationBar'
import WithdrawFundsDrawer from 'components/WithdrawFundsDrawer'
import copy from 'copy-to-clipboard'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { UserWithInviteCodes } from 'types/barn'
import { formatAmountString, shortenString } from 'utils/format'
import { getAddress } from 'viem'
import { useBalance } from 'wagmi'

interface SettingsProps {
  user?: UserWithInviteCodes
}

const Settings = ({ user }: SettingsProps) => {
  const navigate = useNavigate()
  const { authenticated, exportWallet, logout, ready } = usePrivy()
  const { wallet: activeWallet } = usePrivyWagmi()
  const { data: balance } = useBalance({
    address: activeWallet ? getAddress(activeWallet.address) : undefined
  })

  // add funds drawer
  const {
    isOpen: isAddFundsDrawerOpen,
    onClose: onAddFundsDrawerClose,
    onOpen: onAddFundsDrawerOpen
  } = useDisclosure()

  // withdraw funds drawer
  const {
    isOpen: isWithdrawFundsDrawerOpen,
    onClose: onWithdrawFundsDrawerClose,
    onOpen: onWithdrawFundsDrawerOpen
  } = useDisclosure()

  if (!ready) {
    return (
      <Center pt="72px">
        <Spinner />
      </Center>
    )
  }

  if (!authenticated) {
    return null
  }

  return (
    <VStack px={4} pb={4} spacing={12}>
      <NavigationBar title="Settings" showBackButton mb={-4} />

      <HStack w="full" align="center">
        <Box boxSize={12} bg="gray.400" borderRadius="full" />
        <VStack align="flex-start" spacing={1}>
          <Heading size="sm">{user?.username}</Heading>
          {activeWallet ? (
            <HStack spacing={1}>
              <Text fontSize="sm" textColor="gray.400">
                {shortenString(activeWallet.address)}
              </Text>
              <IconButton
                size="xs"
                variant="ghost"
                aria-label="copy wallet address"
                color="gray.400"
                icon={<CopyIcon />}
                onClick={() => copy(activeWallet.address)}
              />
            </HStack>
          ) : null}
        </VStack>
        <Spacer />
        {balance ? (
          <VStack align="flex-end" spacing={1}>
            <Heading size="sm">
              {formatAmountString(balance.formatted)} {balance.symbol}
            </Heading>
            <Text fontSize="sm" textColor="gray.400">
              Wallet balance
            </Text>
          </VStack>
        ) : null}
      </HStack>

      <SimpleGrid w="full" columns={2} spacing={4}>
        <Button onClick={onAddFundsDrawerOpen}>Add Funds</Button>
        <Button variant="outline" onClick={onWithdrawFundsDrawerOpen}>
          Withdraw
        </Button>
      </SimpleGrid>

      <Button
        variant="gray-outline"
        w="full"
        onClick={() => navigate('/profile/wallet/manage')}
      >
        Manage login methods
      </Button>

      <Button variant="gray-outline" w="full" onClick={exportWallet}>
        Export wallet
      </Button>

      <Button variant="gray-outline" w="full" onClick={logout}>
        Log out
      </Button>

      <AddFundsDrawer
        isOpen={isAddFundsDrawerOpen}
        onClose={onAddFundsDrawerClose}
        user={user}
      />
      <WithdrawFundsDrawer
        isOpen={isWithdrawFundsDrawerOpen}
        onClose={onWithdrawFundsDrawerClose}
      />
    </VStack>
  )
}

export default Settings
