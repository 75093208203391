import { Box, Center, Flex, Hide, Show, Spinner } from '@chakra-ui/react'
import { usePrivy } from '@privy-io/react-auth'
import { usePrivyWagmi } from '@privy-io/wagmi-connector'
import ScrollToTop from 'components/ScrollToTop'
import TabBar from 'components/TabBar'
import WebSideMenu from 'components/WebSideMenu'
import { appChain } from 'config'
import { useGetCurrentUser } from 'hooks/useBarn'
import Activity from 'pages/Activity'
import BuySellPass from 'pages/Creator/BuySellPass'
import Creator from 'pages/Creator/Creator'
import CreateCollection from 'pages/Drops/CreateCollection'
import CreateDrop from 'pages/Drops/CreateDrop'
import ManageCollections from 'pages/Drops/ManageCollections'
import ManageDrops from 'pages/Drops/ManageDrops'
import Explore from 'pages/Explore'
import Home from 'pages/Home'
import ManageLoginMethods from 'pages/ManageLoginMethods'
import EnterInviteCode from 'pages/Onboarding/EnterInviteCode'
import FundWallet from 'pages/Onboarding/FundWallet'
import Login from 'pages/Onboarding/Login'
import SetupProfile from 'pages/Onboarding/SetupProfile'
import EditProfile from 'pages/Profile/EditProfile'
import InviteCodes from 'pages/Profile/InviteCodes'
import Profile from 'pages/Profile/Profile'
import UserPortfolio from 'pages/Profile/UserPortfolio'
import Settings from 'pages/Settings'
import React, { useEffect } from 'react'
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes
} from 'react-router-dom'
import { UserWithInviteCodes } from 'types/barn'

const ProtectedRoute: React.FC<{ user?: UserWithInviteCodes }> = ({ user }) => {
  const { authenticated } = usePrivy()

  if (!authenticated || !user) {
    return <Navigate to="/login" replace />
  }

  if (user.status === 'pending') {
    return <Navigate to="/login/enter-code" replace />
  }

  return <Outlet />
}

const AppRoutes = ({ user }: { user?: UserWithInviteCodes }) => {
  return (
    <Box w="full" maxW="620px">
      <Routes>
        <Route element={<ProtectedRoute user={user} />}>
          <Route path="/" element={<Home />} />
          <Route path="/activity" element={<Activity />} />
          <Route path="/explore" element={<Explore />} />

          <Route path="/creator/:id" element={<Creator />} />
          <Route path="/creator/:id/trade" element={<BuySellPass />} />

          <Route path="/profile" element={<Profile />} />
          <Route path="/profile/wallet" element={<Settings user={user} />} />
          <Route
            path="/profile/wallet/manage"
            element={<ManageLoginMethods />}
          />
          <Route path="/profile/portfolio" element={<UserPortfolio />} />
          <Route path="/profile/edit" element={<EditProfile />} />
          <Route
            path="/profile/invite-codes"
            element={<InviteCodes user={user} />}
          />

          <Route path="/collections" element={<ManageCollections />} />
          <Route path="/collections/create" element={<CreateCollection />} />
          <Route
            path="/collections/:collectionAddress/drops"
            element={<ManageDrops />}
          />
          <Route
            path="/collections/:collectionAddress/drops/create"
            element={<CreateDrop />}
          />
        </Route>

        <Route path="/login" element={<Login />} />
        <Route path="/login/enter-code" element={<EnterInviteCode />} />
        <Route path="/login/setup-profile" element={<SetupProfile />} />
        <Route path="/login/fund" element={<FundWallet />} />

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Box>
  )
}

const App = () => {
  const { authenticated, ready } = usePrivy()
  const { wallet } = usePrivyWagmi()
  const { data: user, isLoading: isLoadingUser } = useGetCurrentUser()

  // global switch to app chain
  useEffect(() => {
    if (!wallet) return
    wallet.switchChain(appChain.id)
  }, [wallet])

  if (!ready || (isLoadingUser && authenticated)) {
    return (
      <Center h="100vh">
        <Spinner />
      </Center>
    )
  }

  return (
    <Box pos="relative" minH="100svh">
      <BrowserRouter>
        <ScrollToTop />

        <Hide above="xl">
          <Center>
            <AppRoutes user={user} />
          </Center>
        </Hide>

        <Show above="xl">
          <Center>
            <Flex gap="100px">
              {authenticated && user?.status === 'verified' ? (
                <WebSideMenu />
              ) : null}
              <Box w="620px">
                <AppRoutes user={user} />
              </Box>
            </Flex>
          </Center>
        </Show>

        <Hide above="xl">
          <TabBar />
        </Hide>
      </BrowserRouter>
    </Box>
  )
}

export default App
