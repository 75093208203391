import { ChakraProvider, ColorModeScript, useToken } from '@chakra-ui/react'
import { PrivyProvider } from '@privy-io/react-auth'
import { PrivyWagmiConnector } from '@privy-io/wagmi-connector'
import { RainbowKitProvider } from '@rainbow-me/rainbowkit'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { appChain, wagmiChainsConfig, webWagmiConfig } from 'config'
import DepositFromWeb from 'pages/DepositFromWeb'
import React from 'react'
import ReactDOM from 'react-dom/client'
import theme from 'theme'
import { WagmiConfig } from 'wagmi'

import App from './App'
import reportWebVitals from './reportWebVitals'

const privyAppId = process.env.REACT_APP_PRIVY_APP_ID
if (!privyAppId) throw new Error('REACT_APP_PRIVY_APP_ID not set')

const container = document.getElementById('root')
if (!container) throw new Error('Failed to find the root element')
const root = ReactDOM.createRoot(container)

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false, retry: false } }
})

const WebOnlyApp = () => {
  return (
    <ChakraProvider theme={theme}>
      <WagmiConfig config={webWagmiConfig}>
        <RainbowKitProvider chains={wagmiChainsConfig.chains}>
          <QueryClientProvider client={queryClient}>
            <DepositFromWeb />
          </QueryClientProvider>
        </RainbowKitProvider>
      </WagmiConfig>
    </ChakraProvider>
  )
}

const DefaultApp = () => {
  // const [isPWA, setIsPWA] = useState(false)
  const [accentColor] = useToken('colors', ['accent'])

  // useEffect(() => {
  //   setIsPWA(isRunningStandalone())
  // }, [])

  return (
    <PrivyProvider
      appId={privyAppId}
      config={{
        appearance: {
          accentColor: accentColor as `#${string}`,
          theme: 'dark'
        },
        defaultChain: appChain,
        embeddedWallets: {
          createOnLogin: 'users-without-wallets'
        },
        loginMethods: ['twitter', 'email', 'apple', 'google'],
        supportedChains: [appChain]
      }}
    >
      <PrivyWagmiConnector wagmiChainsConfig={wagmiChainsConfig}>
        <ChakraProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </ChakraProvider>
      </PrivyWagmiConnector>
    </PrivyProvider>
  )
}

root.render(
  <React.StrictMode>
    <ColorModeScript />
    {window.location.pathname.startsWith('/deposit') ? (
      <WebOnlyApp />
    ) : (
      <DefaultApp />
    )}
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
