import {
  Button,
  Heading,
  Skeleton,
  TabPanel,
  Text,
  VStack
} from '@chakra-ui/react'
import NumericalInput from 'components/NumericalInput'
import useSellShares from 'hooks/useSellShares'
import useSharesBalance from 'hooks/useSharesBalance'
import React, { useState } from 'react'
import { formatAmount } from 'utils/format'
import { usePublicClient } from 'wagmi'

const SellSharesPanel = ({ creatorAddress }: { creatorAddress: string }) => {
  const publicClient = usePublicClient()

  const [amount, setAmount] = useState('')
  const amountNum = Number(amount)

  const {
    data: sharesBalance,
    isLoading: isLoadingSharesBalance,
    refetch: refetchSharesBalance
  } = useSharesBalance({
    subjectAddress: creatorAddress
  })
  const hasEnoughShares = amountNum <= Number(sharesBalance)

  const { sellPrice, writeAsync } = useSellShares({
    amount: amountNum,
    enabled:
      sharesBalance !== undefined ? amountNum <= Number(sharesBalance) : false,
    subjectAddress: creatorAddress
  })

  const onBuyClick = async () => {
    if (!writeAsync) return
    const result = await writeAsync()
    const receipt = await publicClient.waitForTransactionReceipt({
      hash: result.hash
    })
    if (receipt.status === 'success') {
      setAmount('')
      refetchSharesBalance()
    }
  }

  return (
    <TabPanel>
      <VStack spacing={10} mt={10}>
        <VStack spacing={1} w="full">
          <Skeleton isLoaded={!isLoadingSharesBalance}>
            <Text fontSize="sm" textColor="gray.400">
              You have {Number(sharesBalance || 0)} creator passes
            </Text>
          </Skeleton>

          <NumericalInput
            value={amount}
            onValueChange={setAmount}
            placeholder="0"
            inputType="integer"
            isInvalid={!hasEnoughShares && amount !== '' && amountNum > 0}
          />
        </VStack>

        <VStack spacing={1}>
          <Text fontSize="sm" textColor="gray.400">
            You will get:
          </Text>
          <Heading size="md">
            {sellPrice ? formatAmount(sellPrice, 18) : '--'} ETH
          </Heading>
        </VStack>

        <Button
          w="full"
          size="lg"
          isDisabled={!amount || !writeAsync}
          onClick={onBuyClick}
        >
          {!hasEnoughShares ? 'Not enough shares' : 'Sell'}
        </Button>
      </VStack>
    </TabPanel>
  )
}

export default SellSharesPanel
