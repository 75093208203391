import { Center, Icon, SimpleGrid } from '@chakra-ui/react'
import { ReactComponent as BellIcon } from 'assets/icons/bell.svg'
import { ReactComponent as HomeIcon } from 'assets/icons/home.svg'
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg'
import { ReactComponent as ProfileIcon } from 'assets/icons/user.svg'
import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'

const TabBar = () => {
  const { pathname } = useLocation()

  const hideTabBar =
    pathname.startsWith('/login') || pathname.startsWith('/deposit')
  if (hideTabBar) return null

  return (
    <SimpleGrid
      pos="fixed"
      w="full"
      bottom={0}
      pb="env(safe-area-inset-bottom)"
      left={0}
      h="calc(56px + env(safe-area-inset-bottom))"
      columns={4}
      zIndex="sticky"
      bg="rgba(0, 0, 0, 0.25)"
      borderTopRadius="30px"
      boxShadow="0px 0px 20px 0px rgba(0, 0, 0, 0.20)"
      backdropFilter="blur(12.5px)"
    >
      <TabBarItem
        icon={
          <Icon
            as={HomeIcon}
            boxSize="30px"
            stroke={pathname === '/' ? 'accent' : 'white'}
          />
        }
        to="/"
      />
      <TabBarItem
        icon={
          <Icon
            as={BellIcon}
            boxSize="30px"
            fill={pathname === '/activity' ? 'accent' : 'white'}
          />
        }
        to="/activity"
      />
      <TabBarItem
        icon={
          <Icon
            as={SearchIcon}
            boxSize="30px"
            fill={pathname === '/explore' ? 'accent' : 'white'}
          />
        }
        to="/explore"
      />
      <TabBarItem
        icon={
          <Icon
            boxSize="30px"
            as={ProfileIcon}
            fill={pathname.startsWith('/profile') ? 'accent' : 'white'}
          />
        }
        to="/profile"
      />
    </SimpleGrid>
  )
}

interface TabBarItemProps {
  icon: React.ReactNode
  to: string
}

const TabBarItem = ({ icon, to }: TabBarItemProps) => {
  return (
    <Center as={NavLink} h="full" to={to}>
      {icon}
    </Center>
  )
}

export default TabBar
