import { getAddress, parseAbi } from 'viem'
import { useContractWrite } from 'wagmi'

interface UseClaimDropProps {
  dropAddress: string
  dropId: number
}

const useClaimDrop = ({ dropAddress, dropId }: UseClaimDropProps) => {
  const { writeAsync } = useContractWrite({
    abi: parseAbi(['function claim(uint256 dropId) external']),
    address: getAddress(dropAddress),
    args: [BigInt(dropId)],
    functionName: 'claim'
  })

  return {
    writeAsync
  }
}

export default useClaimDrop
