import {
  Box,
  Button,
  Heading,
  HStack,
  Image,
  Text,
  Textarea,
  VStack
} from '@chakra-ui/react'
import CameraIcon from 'assets/camera.svg'
import { BackButton, NavigationBarContainer } from 'components/NavigationBar'
import useCreateDrop from 'hooks/useCreateDrop'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

const CreateDrop = () => {
  const { collectionAddress } = useParams() as { collectionAddress: string }
  const navigate = useNavigate()

  const [caption, setCaption] = React.useState('')

  const { createDrop } = useCreateDrop({
    collectionAddress,
    startTime: 1696176928,
    tiersMetadata: [
      'ipfs://bafkreielpuyzqtupqaikna5ctcmevkgubs5fbwwfwxw62kpso2qvnx6tqi'
    ],
    tiersMinHolding: [1]
  })

  const onCreateClick = async () => {
    const receipt = await createDrop()
    console.log(receipt)
    switch (receipt.status) {
      case 'success':
        // TODO: post drop to backend with caption?
        navigate(-1)
        break
      case 'reverted':
        console.log('TODO')
        break
    }
  }

  return (
    <Box bg="gray.700" minH="100svh">
      <NavigationBarContainer>
        <BackButton />
        <Heading size="md" alignSelf="center">
          Create Drop
        </Heading>
      </NavigationBarContainer>

      <VStack p={4} pt="72px" spacing={10}>
        <Textarea
          placeholder="What's this drop about?"
          value={caption}
          onChange={(e) => setCaption(e.currentTarget.value)}
        />

        <HStack
          borderRadius="lg"
          border="1px solid"
          borderColor="gray.500"
          w="full"
          p={4}
        >
          <Image src={CameraIcon} />
          <Text fontWeight="bold">Add image</Text>
        </HStack>

        <Button
          w="full"
          size="lg"
          isDisabled={!caption}
          onClick={onCreateClick}
        >
          Create
        </Button>
      </VStack>
    </Box>
  )
}

export default CreateDrop
