import { AddIcon } from '@chakra-ui/icons'
import {
  Box,
  Heading,
  HStack,
  Icon,
  Image,
  Spacer,
  Text,
  VStack
} from '@chakra-ui/react'
import {
  BackButton,
  NavigationBarContainer,
  NavigationBarIconButton
} from 'components/NavigationBar'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { userDrops } from 'stub'

const ManageDrops = () => {
  const navigate = useNavigate()
  const { collectionAddress } = useParams()

  const collectionName = userDrops[0].name

  return (
    <Box>
      <NavigationBarContainer>
        <BackButton />
        <Heading size="md" alignSelf="center">
          {collectionName}
        </Heading>
        <NavigationBarIconButton
          aria-label="create-drop"
          icon={<Icon as={AddIcon} fill="white" />}
          right={4}
          onClick={() =>
            navigate(`/collections/${collectionAddress}/drops/create`)
          }
        />
      </NavigationBarContainer>

      <VStack p={4} pt="72px" spacing={6}>
        {userDrops.map((drop, i) => {
          return (
            <VStack
              key={i}
              spacing={2}
              align="flex-start"
              w="full"
              borderBottom="1px solid"
              borderBottomColor="gray.600"
              pb={4}
            >
              <HStack w="full">
                <Heading size="sm">Drop #{i + 1}</Heading>
                <Spacer />
                <Text fontSize="xs" textColor="gray.400" textAlign="right">
                  Starts at: {new Date(drop.startTime).toLocaleString()}
                </Text>
              </HStack>

              <Image src={drop.tiersImages[0]} borderRadius="2xl" />

              <HStack spacing={1}>
                <Text textColor="gray.400">Total minted:</Text>
                <Text fontWeight="bold">{drop.totalMinted}</Text>
              </HStack>
            </VStack>
          )
        })}
      </VStack>
    </Box>
  )
}

export default ManageDrops
