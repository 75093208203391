import {
  Box,
  Heading,
  HStack,
  Image,
  SimpleGrid,
  Spacer,
  Tag,
  Text,
  VStack
} from '@chakra-ui/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'

export interface CreatorPass {
  holders: number
  id: string
  minted: number
  name: string
  passPrice: number
  pfpUrl: string
  userOwnedQuantity: number
  itemsPreview?: { imageUrl: string }[]
}

interface CreatorPassGridProps {
  passes: CreatorPass[]
}

const CreatorPassGrid = ({ passes }: CreatorPassGridProps) => {
  const navigate = useNavigate()

  return (
    <SimpleGrid spacing={4}>
      {passes.map((pass, i) => {
        return (
          <Box
            key={i}
            borderBottom="1px"
            borderBottomColor="gray.600"
            pb={4}
            cursor="pointer"
            onClick={() => navigate(`/creator/${pass.id}`)}
          >
            <HStack spacing={3}>
              <Image src={pass.pfpUrl} boxSize="70px" borderRadius="full" />
              <VStack align="flex-start" w="full">
                <HStack w="full">
                  <Heading size="md">{pass.name}</Heading>
                  <Spacer />
                  <Tag
                    borderRadius="full"
                    size="sm"
                    fontWeight="bold"
                    textColor="gray.400"
                    fontSize="10px"
                  >
                    HOLDING
                    <Text textColor="white" ml={1}>
                      {pass.userOwnedQuantity}
                    </Text>
                  </Tag>
                </HStack>
                <SimpleGrid columns={3} spacing={5}>
                  <VStack align="flex-start" spacing={0}>
                    <Text textColor="gray.400" fontSize="xs">
                      Holders:
                    </Text>
                    <Text fontWeight="bold">{pass.holders}</Text>
                  </VStack>
                  <VStack align="flex-start" spacing={0}>
                    <Text textColor="gray.400" fontSize="xs">
                      Minted:
                    </Text>
                    <Text fontWeight="bold">{pass.minted}</Text>
                  </VStack>
                  <VStack align="flex-start" spacing={0}>
                    <Text textColor="gray.400" fontSize="xs">
                      Pass Price:
                    </Text>
                    <Text fontWeight="bold">{pass.passPrice} ETH</Text>
                  </VStack>
                </SimpleGrid>
              </VStack>
            </HStack>
            {pass.itemsPreview ? (
              <SimpleGrid columns={4} spacing={2} pt={4}>
                {pass.itemsPreview.map((itemPreview, i) => {
                  return (
                    <Image
                      key={i}
                      src={itemPreview.imageUrl}
                      borderRadius="md"
                    />
                  )
                })}
              </SimpleGrid>
            ) : null}
          </Box>
        )
      })}
    </SimpleGrid>
  )
}

export default CreatorPassGrid
