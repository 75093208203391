import { AddIcon, ChevronRightIcon } from '@chakra-ui/icons'
import {
  Box,
  Heading,
  HStack,
  Icon,
  Spacer,
  Text,
  VStack
} from '@chakra-ui/react'
import {
  BackButton,
  NavigationBarContainer,
  NavigationBarIconButton
} from 'components/NavigationBar'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { userCollections } from 'stub'

const ManageCollections = () => {
  const navigate = useNavigate()

  return (
    <Box>
      <NavigationBarContainer>
        <BackButton />
        <Heading size="md" alignSelf="center">
          My Collections
        </Heading>
        <NavigationBarIconButton
          aria-label="create-drop"
          icon={<Icon as={AddIcon} fill="white" />}
          right={4}
          onClick={() => navigate('/collections/create')}
        />
      </NavigationBarContainer>

      <VStack p={4} pt="72px" spacing={6}>
        {userCollections.map((collection) => {
          return (
            <HStack
              w="full"
              key={collection.address}
              border="1px solid"
              p={4}
              borderRadius="lg"
              borderColor="gray.600"
              onClick={() =>
                navigate(`/collections/${collection.address}/drops`)
              }
            >
              <VStack spacing={1} align="flex-start">
                <Heading size="sm">{collection.name}</Heading>
                <Text textColor="gray.400">{collection.symbol}</Text>
              </VStack>

              <Spacer />

              <ChevronRightIcon />
            </HStack>
          )
        })}
      </VStack>
    </Box>
  )
}

export default ManageCollections
