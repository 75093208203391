import { Activity } from 'components/ActivitiesList'
import { CreatorDrop } from 'components/CreatorDropsList'
import { CreatorPass } from 'components/CreatorPassesGrid'

// TODO: remove all stubs

export const passes: CreatorPass[] = Array(10).fill({
  holders: 154,
  id: 1,
  minted: 22,
  name: 'Creator A',
  passPrice: 0.5,
  pfpUrl:
    'https://i.seadn.io/gae/AwbbtNC0Tmv_lPOLEU9hZT4jPTvVzGjSNE_zDJRdW7WdmKYP6D-IZRug2i8DwOtJ0DOmPLohQpy8ZiMDO3jaIwlXAXSqGWZKtdJqSA?auto=format&dpr=1&w=384',
  userOwnedQuantity: 0
})

export const passesWithItemsPreview: CreatorPass[] = Array(10).fill({
  holders: 154,
  id: 1,
  itemsPreview: [
    {
      imageUrl:
        'https://i.seadn.io/gcs/files/640c9b283dd80e221a26ff5018530708.png?auto=format&dpr=1&w=1000'
    },
    {
      imageUrl:
        'https://i.seadn.io/gcs/files/640c9b283dd80e221a26ff5018530708.png?auto=format&dpr=1&w=1000'
    },
    {
      imageUrl:
        'https://i.seadn.io/gcs/files/640c9b283dd80e221a26ff5018530708.png?auto=format&dpr=1&w=1000'
    },
    {
      imageUrl:
        'https://i.seadn.io/gcs/files/640c9b283dd80e221a26ff5018530708.png?auto=format&dpr=1&w=1000'
    }
  ],
  minted: 22,
  name: 'Creator A',
  passPrice: 0.5,
  pfpUrl:
    'https://i.seadn.io/gae/AwbbtNC0Tmv_lPOLEU9hZT4jPTvVzGjSNE_zDJRdW7WdmKYP6D-IZRug2i8DwOtJ0DOmPLohQpy8ZiMDO3jaIwlXAXSqGWZKtdJqSA?auto=format&dpr=1&w=384',
  userOwnedQuantity: 2
})

export const creatorDrops: CreatorDrop[] = Array(10).fill({
  address: '0xD1D4D200e5Bf7f173f8876BBAAec6c18625e7b6A',
  caption:
    'This week’s inspiration: New Orlean’s historical street gangs! Drop is limited for only 20 copies, so hurry!',
  createdAt: 0,
  creator: {
    id: 1,
    name: 'Creator B',
    pfpUrl:
      'https://i.seadn.io/gae/AwbbtNC0Tmv_lPOLEU9hZT4jPTvVzGjSNE_zDJRdW7WdmKYP6D-IZRug2i8DwOtJ0DOmPLohQpy8ZiMDO3jaIwlXAXSqGWZKtdJqSA?auto=format&dpr=1&w=384'
  },
  id: 0,
  item: {
    imageUrl:
      'https://i.seadn.io/gcs/files/640c9b283dd80e221a26ff5018530708.png?auto=format&dpr=1&w=1000',
    name: '#45 Item XXX'
  },
  totalMinted: 56,
  totalSupply: 200
})

export const activities: Activity[] = [
  {
    price: 40.59,
    subject: {
      creatorId: 1,
      name: 'Blue'
    },
    timestamp: 0,
    type: 'buy',
    user: {
      id: 1,
      name: 'User B',
      pfpUrl:
        'https://i.seadn.io/gae/AwbbtNC0Tmv_lPOLEU9hZT4jPTvVzGjSNE_zDJRdW7WdmKYP6D-IZRug2i8DwOtJ0DOmPLohQpy8ZiMDO3jaIwlXAXSqGWZKtdJqSA?auto=format&dpr=1&w=384'
    }
  },
  {
    price: 0.0045,
    subject: {
      creatorId: 1,
      name: 'Drop #11'
    },
    timestamp: 0,
    type: 'mint',
    user: {
      id: 2,
      name: 'User B',
      pfpUrl:
        'https://i.seadn.io/gae/AwbbtNC0Tmv_lPOLEU9hZT4jPTvVzGjSNE_zDJRdW7WdmKYP6D-IZRug2i8DwOtJ0DOmPLohQpy8ZiMDO3jaIwlXAXSqGWZKtdJqSA?auto=format&dpr=1&w=384'
    }
  },
  {
    price: 0.00112,
    subject: {
      creatorId: 1,
      name: 'Drop #12'
    },
    timestamp: 0,
    type: 'sell',
    user: {
      id: 3,
      name: 'User B',
      pfpUrl:
        'https://i.seadn.io/gae/AwbbtNC0Tmv_lPOLEU9hZT4jPTvVzGjSNE_zDJRdW7WdmKYP6D-IZRug2i8DwOtJ0DOmPLohQpy8ZiMDO3jaIwlXAXSqGWZKtdJqSA?auto=format&dpr=1&w=384'
    }
  }
]

export const userCollections = [
  {
    address: '0x23a652eb4136f89758ad07ecFe8f2Eb50535b3cD',
    name: 'Collection A',
    symbol: 'COLA'
  }
]

export const userDrops = [
  {
    address: '0x0dA7fA0E4C26d76c7942cb15e8553E40C47c0b3b',
    name: 'Collection A',
    startTime: 0,
    symbol: 'COLA',
    tiersImages: [
      'https://i.seadn.io/s/primary-drops/0x562fc2927c77cb975680088566ada1dc6cb8b5ea/27805749:about:preview_media:642860a4-b76b-4584-a9dc-eb3a4c176d70.png?auto=format&dpr=1&w=3840'
    ],
    tiersMinHolding: [1],
    totalMinted: 30
  }
]
