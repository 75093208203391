import {
  Box,
  Button,
  Center,
  CenterProps,
  Heading,
  HStack,
  Icon,
  IconButton,
  IconButtonProps,
  Spacer,
  Text,
  VStack
} from '@chakra-ui/react'
import { usePrivy } from '@privy-io/react-auth'
import { usePrivyWagmi } from '@privy-io/wagmi-connector'
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow-left.svg'
import { useGetCurrentUser } from 'hooks/useBarn'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { shortenString } from 'utils/format'

export const ProfileNavigationBar = () => {
  const { authenticated, ready } = usePrivy()
  const { wallet: activeWallet } = usePrivyWagmi()

  const { data: barnUser } = useGetCurrentUser()

  const navigate = useNavigate()

  if (!ready || !authenticated) {
    return <Heading size="md">Profile</Heading>
  }

  return (
    <HStack w="full" p={4} align="center">
      <Box boxSize={12} bg="gray.400" borderRadius="full" />
      <VStack align="flex-start" spacing={1}>
        <Heading size="sm">{barnUser?.username}</Heading>
        <Text fontSize="sm" textColor="gray.400">
          {shortenString(activeWallet?.address)}
        </Text>
      </VStack>
      <Spacer />
      <Button
        variant="gray-outline"
        borderRadius="full"
        size="sm"
        onClick={() => navigate('/profile/edit')}
      >
        Edit
      </Button>
    </HStack>
  )
}

export const NavigationBarIconButton = (props: IconButtonProps) => {
  return (
    <IconButton
      variant="ghost"
      bg="gray.700"
      pos="absolute"
      size="sm"
      borderRadius="full"
      {...props}
    />
  )
}

export const BackButton = () => {
  const navigate = useNavigate()

  return (
    <NavigationBarIconButton
      aria-label="back"
      icon={<Icon as={ArrowLeft} fill="white" />}
      left={{ base: 4, xl: 0 }}
      onClick={() => navigate(-1)}
    />
  )
}

export const NavigationBarContainer = (props: CenterProps) => {
  return (
    <Center
      minH="56px"
      w="full"
      pos="sticky"
      top={0}
      bg="black"
      zIndex="sticky"
      {...props}
    />
  )
}

interface NavigationBarProps {
  title: string
  showBackButton?: boolean
}

export const NavigationBar = ({
  showBackButton,
  title,
  ...props
}: NavigationBarProps & CenterProps) => {
  return (
    <NavigationBarContainer {...props}>
      {showBackButton ? <BackButton /> : null}
      <Heading size="md" alignSelf="center">
        {title}
      </Heading>
    </NavigationBarContainer>
  )
}
