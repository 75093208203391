import { Box, VStack } from '@chakra-ui/react'
import EditProfileForm from 'components/EditProfileForm'
import { NavigationBar } from 'components/NavigationBar'
import React from 'react'

const EditProfile = () => {
  return (
    <Box>
      <NavigationBar title="Edit Profile" showBackButton />

      <VStack
        h="calc(100vh - 56px)"
        pb="calc(env(safe-area-inset-bottom) + 50px)"
        justify="flex-end"
      >
        <EditProfileForm onEditProfileSuccess={() => console.log('TODO')} />
      </VStack>
    </Box>
  )
}

export default EditProfile
