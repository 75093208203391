import {
  usePrepareSendTransaction,
  useSendTransaction,
  useWaitForTransaction
} from 'wagmi'

interface UseSendETHProps {
  amount: bigint | undefined
  enabled: boolean
  to?: string
}

const useSendETH = ({ amount, enabled, to }: UseSendETHProps) => {
  const { config } = usePrepareSendTransaction({
    enabled,
    to,
    value: amount ? amount : undefined
  })

  const { data, sendTransactionAsync } = useSendTransaction(config)

  const { data: receipt, isLoading } = useWaitForTransaction({
    hash: data?.hash
  })

  return {
    isLoading,
    receipt,
    sendTransaction: sendTransactionAsync
  }
}

export default useSendETH
